<template>
  <div style="" class="modal fade" id="mobileVerifyModalContentCom">
    <div class="modal-dialog modal-md modalCenterClass">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <button
                type="button"
                style="
                  font-size: 35px;
                  color: grey;
                  background-color: white !important;
                  margin-top: 0px !important;
                  font-weight: 500;
                  right: 30px;
                  top: 5px;
                  position: absolute;
                  z-index: 100;
                "
                data-dismiss="modal"
                class="close"
                @click="closeModal()"
              >
                &times;
              </button>
            </div>
          </div>
          <br />
          <hr />
          <div v-if="condition === 0">
            <!-- change mobile comp -->
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <h4 class="modalH4">Change your mobile no here</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <div class="form-group groupCenterModalClass">
                  <label class="control-label"
                    >Mobile No - {{ mobileForPropval }}</label
                  >
                  <VuePhoneNumberInput
                    valid-color="green"
                    color="red"
                    @input="validatePhoneNO(mobile)"
                    v-model="mobile"
                    class="modalMobileCenterClass"
                    default-country-code="LK"
                    :only-countries="['LK']"
                    no-example
                    @update="setPhoneNo"
                    min="12"
                    maxlength="12"
                    style="height: 30px"
                  />
                </div>
                <div
                  style="color: red"
                  class="invalid-feedback"
                  v-if="
                    String(mobile).length !== 9 && String(mobile).length > 0
                  "
                >
                  Enter valid mobile number
                </div>
              </div>
              <div class="col-md-2 col-sm-2"></div>
            </div>
            <hr />
          </div>
          <div v-if="condition === 1">
            <!-- otp comp buyer profile -->
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <p style="font-size: 13px">
                  Enter your otp code before session expire in
                </p>
                <p>
                  <b
                    style="
                      display: flex;
                      justify-content: center;
                      color: #337ab7;
                      font-size: 15px;
                    "
                  >
                    {{ countdownseconds }} Secs
                  </b>
                </p>
              </div>
              <div class="col-md-2 col-sm-2"></div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <div class="form-group groupCenterModalClass">
                  <input
                    v-model="code.code"
                    type="text"
                    class="form-control"
                    placeholder="Enter your OTP code"
                  />
                </div>
              </div>
              <div class="col-md-2 col-sm-2"></div>
            </div>
            <hr />
          </div>
          <div class="row" v-if="condition === 1">
            <div class="col-md-12 col-sm-12" style="padding-right: 32px">
              <button
                v-if="userType === 'merchant1'"
                class="btn btn-default mob-otp-btn"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
                @click="send"
              >
                Send
              </button>
              <button
                v-if="userType === 'buyer'"
                class="btn btn-default mob-otp-btn"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
                @click="sendCodeBuyer"
                :disabled="String(code.code).length !== 6 && String(code.code).length >= 0"
              >
                Send
              </button>
              <button
                class="btn btn-default mob-otp-btn"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                "
                type="button"
                @click="resend"
                :disabled="countdownseconds !== 0"
              >
                Resend
              </button>
            </div>
          </div>
          <div v-if="condition === 2">
            <!-- otp comp merchant profile-->
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <p style="font-size: 13px">
                  Enter your otp code before session expire in
                </p>
                <p>
                  <b
                    style="
                      display: flex;
                      justify-content: center;
                      color: #337ab7;
                      font-size: 15px;
                    "
                  >
                    {{ countdownseconds }} Secs
                  </b>
                </p>
              </div>
              <div class="col-md-2 col-sm-2"></div>
            </div>
            <div class="row">
              <div class="col-md-2 col-sm-2"></div>
              <div class="col-md-8 col-sm-8">
                <div class="form-group groupCenterModalClass">
                  <input
                    v-model="code.code"
                    type="text"
                    class="form-control"
                    placeholder="Enter your OTP code"
                  />
                </div>
              </div>
              <div class="col-md-2 col-sm-2"></div>
            </div>
            <hr />
          </div>
          <div class="row" v-if="condition === 2">
            <div class="col-md-12 col-sm-12" style="padding-right: 32px">
              <button
                class="btn btn-default"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
                @click="veryfyOtpMerchntProfile"
              >
                Send
              </button>
              <button
                class="btn btn-default"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                "
                type="button"
                @click="resendOtpMerchntProfile"
              >
                Resend
              </button>
            </div>
          </div>
          <div class="row" v-if="condition === 0">
            <div class="col-md-12 col-sm-12" style="padding-right: 32px">
              <button
                v-if="userType === 'buyer'"
                @click="verifyMobile()"
                class="btn btn-default modal-btns-yellow"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
                :disabled="String(mobile).length !== 9 && String(mobile).length >= 0"
              >
                Verify
              </button>
              <button
                v-if="userType === 'merchant1'"
                @click="verifyMobileMerchant()"
                :disabled="!isDisable"
                class="btn btn-default modal-btns-yellow"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
              >
                Verify
              </button>
              <button
                v-if="userType === 'merchant_profile'"
                @click="verifyMobileMerchantProfile()"
                :disabled="!isDisable"
                class="btn btn-default modal-btns-yellow"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                type="button"
              >
                Verify
              </button>
              <button
                v-if="userType === 'merchant'"
                class="btn btn-default modal-btns-yellow"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                  margin-left: 10px;
                "
                :disabled="
                  (String(mobile).length !== 9 && String(mobile).length > 0) ||
                  String(mobile).length == 0
                "
                @click="changeMobile"
                type="button"
              >
                Change
              </button>
              <button
                class="btn btn-default modal-btns-yellow"
                style="
                  height: 30px !important;
                  float: right;
                  border-radius: 5px;
                "
                type="button"
                @click="closeModal()"
              >
                Close
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import mixinAuth from "@/mixins/buyer/APIAuth";
import mixinAuth1 from "@/mixins/merchant/APIAuth";
export default {
  components: { VuePhoneNumberInput },
  mixins: [mixinAuth, mixinAuth1],
  props: {
    merchantId: {
      type: String,
      default: null,
    },
    userType: {
      type: String,
      default: false,
    },
    mobileForPropval: {
      type: String || Number,
      default: null,
    },
  },
  data() {
    return {
      formatedMobile: "",
      mobile: "",
      code: {
        code: "",
      },
      mobileValid: false,
      condition: 0,
      countdownseconds: 0,
      blockresendotp: true,
    };
  },
  computed: {
    isDisable() {
      return this.mobile;
    },
  },
  watch: {
    merchantId: {
      handler(val) {
        if (val) {
          return val;
        }
      },
      immediate: true,
      deep: true,
    },
    mobileForPropval: {
      handler(val) {
        if (val) {
          return val;
        }
      },
      immediate: true,
      deep: true,
    },
    userType: {
      handler(str) {
        if (str) {
          return str;
        }
      },
      immediate: true,
      deep: true,
    },
    countdownseconds: function (val) {
      if (val === 0) {
        this.blockresendotp = false;
      } else {
        this.blockresendotp = true;
      }
    },
  },
  methods: {
    verifyMobileMerchant: async function () {
      try {
        let obj = {};
        obj.mobile = this.mobile.toString();
        obj.id = this.merchantId;
        let res = await this.merchantChangeMobile(obj);
        if (res.data.success === 1) {
          this.countdownCal(90);
          this.condition = 1;
          this.isDisable = true;
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Error in verify",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    verifyMobileMerchantProfile: async function () {
      try {
        let obj = {};
        obj.mobile = this.mobile.toString();
        let res = await this.merchantChangeMobileProfileApi(obj);
        if (res.data.success === 1) {
          this.countdownCal(90);
          this.condition = 2;
          this.isDisable = true;
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Error in verify",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    send: async function () {
      try {
        let res = await this.merchantProfileVeryfyOtpApi(this.code);
        if (res.success === 1) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully verified",
            showConfirmButton: false,
            timer: 1500,
          });
          this.closeModal();
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Error in otp verification",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    sendCodeBuyer: async function () {
      try {
        let res = await this.buyerMobileNoChangeVerify(this.code);
        if (res.success === 1) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully verified",
            showConfirmButton: false,
            timer: 1500,
          });
          this.closeModal();
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Error in otp verification",
          showConfirmButton: false,
          timer: 1500,
        });
        throw error;
      }
    },
    veryfyOtpMerchntProfile: async function () {
      try {
        let res = await this.merchantProfileVeryfyOtpApi(this.code);
        if (res) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully verified",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.closeModal();
        }
      } catch (error) {
        this.closeModal();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    resend: async function () {
      try {
        let obj = {};
        obj.mobile = this.mobile.toString();
        let res = await this.buyerMobileNoChange(obj);
        if (res.success === 1) {
          this.countdownCal(90);
        }
      } catch (error) {
        if (error.status == 409) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Mobile number is already existing.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Error in verify",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        throw error;
      }
    },
    resendOtpMerchntProfile: async function () {
      try {
        let obj = {};
        obj.mobile = this.mobile.toString();
        let res = await this.buyerMobileNoChange(obj);
        if (res.success === 1) {
          this.countdownCal(90);
        }
      } catch (error) {
        if (error.status == 409) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Mobile number is already existing.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Error in verify",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        throw error;
      }
    },
    verifyMobile: async function () {
      try {
        let obj = {};
        obj.mobile = "+94" + this.mobile.toString();
        let res = await this.buyerMobileNoChange(obj);
        if (res.success === 1) {
          this.countdownCal(90);
          this.condition = 1;
          this.isDisable = true;
        }
      } catch (error) {
        if (error.status == 409 || error.status == 408) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Mobile number is already existing.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Error in verify",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        throw error;
      }
    },
    countdownCal(duration) {
      duration = 90;
      this.countdownseconds = duration;
      let self = this;
      var downloadTimer = setInterval(function () {
        self.countdownseconds -= 1;
        if (self.countdownseconds <= 0) {
          clearInterval(downloadTimer);
        }
      }, 1000);
    },
    closeModal() {
      $("#mobileVerifyModalContentCom").modal("hide");
      this.condition = 0;
      this.isDisable = false;
      this.code.code = "";
      this.mobile = "";
      this.$emit("close", true);
    },
    validatePhoneNO(key) {
      if (key === "0") {
        this.mobile = "";
      }
      if (key.charAt(0) === "0" || this.mobile.charAt(0) === "0") {
        key = key.substr(1);
        this.mobile = this.mobile.substr(1);
        if (
          key.charAt(3) === " " ||
          this.mobile.charAt(3) === " " ||
          key.charAt(7) === " " ||
          this.mobile.charAt(7) === " "
        ) {
          this.mobile = this.mobile.replace(/ /g, "");
          key = this.mobile;
        }
        if (key.length == 9) {
          this.mobileValid = true;
        } else if (key.length <= 10) {
          this.mobileValid = false;
        }
      }
      if (key.length == 9) {
        this.mobileValid = true;
      } else if (key.length <= 10) {
        this.mobileValid = false;
      }
    },
    setPhoneNo(payload) {
      this.mobile = payload.formattedNumber;
      this.formatedMobile = payload.formattedNumber;
    },
    changeMobile(payload) {
      this.$emit("emitTosetMobileNo", this.formatedMobile);
    },
  },
};
</script>
<style scoped>
.modal-btns-yellow {
  background: #ffd338;
  color: #000000;
  border: none;
}
.modal-btns-yellow:hover {
  background: #ffd338;
  color: #ffffff;
}
.mob-otp-btn {
  background: #ffd338;
  color: #000000;
  border: none;
}
.mob-otp-btn:hover {
  background: #ffd338;
  color: #ffffff;
}
@media (max-width: 2880px) {
  .modalCenterClass {
    position: absolute;
    top: 36%;
    left: 36%;
  }
}
@media (max-width: 1920px) {
  .modalCenterClass {
    position: absolute;
    top: 36%;
    left: 36%;
  }
}
@media (max-width: 1880px) {
  .modalCenterClass {
    position: absolute;
    top: 36%;
    left: 36%;
  }
}
@media (max-width: 1440px) {
  .modalCenterClass {
    position: absolute;
    top: 35%;
    left: 30%;
  }
}
@media (max-width: 1280px) {
  .modalCenterClass {
    position: absolute;
    top: 37%;
    left: 26%;
  }
}
@media (max-width: 768px) {
  .modalCenterClass {
    position: absolute;
    top: 40%;
    left: 11%;
  }
}
@media (max-width: 475px) {
  .modalCenterClass {
    position: absolute;
    top: 30%;
    left: 3%;
  }
  .modalH4 {
    padding-left: 10px;
  }
  .groupCenterModalClass {
    padding-left: 35px;
  }
  .modalMobileCenterClass {
    width: 90%;
  }
}
#mobileVerifyModalContentCom .modal-header {
  border-bottom: none !important;
}

#mobileVerifyModalContentCom .modal-footer {
  border-top: none !important;
}
</style>