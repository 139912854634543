<template>
  <div id="stepCont">
    <stepper :options="stepperOptions">
      <!-- Step 01 -->
      <div slot="step-1" class="step-content-box">
        <h3>We have sent an OTP code to the mobile no. that you entered</h3>
        <div class="step-content-text">
          <div class="chg-phone-number">{{ form.mobile }}</div>
          <a
            style="color: #ffd338"
            href=""
            data-toggle="modal"
            data-target="#mobileVerifyModalContentCom"
            @click="showChangeMobileModal()"
            >Change number</a
          >
        </div>
        <div class="input-container">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input1"
                  @input="movetoNext('first', 'second')"
                  class="form-control input-lg"
                  id="first"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input2"
                  class="form-control input-lg"
                  @input="movetoNext('second', 'third')"
                  id="second"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input3"
                  class="form-control input-lg"
                  @input="movetoNext('third', 'forth')"
                  id="third"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input4"
                  class="form-control input-lg"
                  @input="movetoNext('forth', 'fifth')"
                  id="forth"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input5"
                  class="form-control input-lg"
                  @input="movetoNext('fifth', 'sixth')"
                  id="fifth"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 otp-digit">
              <div class="form-group">
                <input
                  type="text"
                  v-model="input6"
                  class="form-control input-lg"
                  id="sixth"
                  maxlength="1"
                  style="text-align: center"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="step-content-text">
          <div class="enter-code">
            Enter your code before the session expires
          </div>
          <div style="color: #ffd338" class="rel-time">{{ time }}</div>
          <!-- <div class="rel-time">{{countdownseconds}} secs</div> -->
          <div v-if="this.time == 0 + ' min ' + 0 + ' secs '" class="rec-code">
            Did not receive your code?
            <a @click="resentOTP" style="color: #ffd338">Resend</a>
          </div>
        </div>
        <div class="align-right-rec">
          <input
            type="button"
            :disabled="
              disableOtpBtn ||
              this.input1 === '' ||
              this.input2 === '' ||
              this.input3 === '' ||
              this.input4 === '' ||
              this.input5 === '' ||
              this.input6 === ''
            "
            @click="veryfyOtp()"
            class="btn merchregstep-btn btn-next"
            value="Verify"
          />
        </div>
      </div>
    </stepper>
    <mobileVerifyModal
      :userType="userType"
      :mobileForPropval="mobileForPropval"
      @emitTosetMobileNo="changeMobileNo"
      ref="modal"
    />
  </div>
</template>
<script>
import Stepper from "vuejs-stepper";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import mobileVerifyModal from "@/components/common/modals/mobileVerifyModal.vue";
import mixinProfile from "@/mixins/merchant/APIProfile";
import mixinAPIAuth from "@/mixins/merchant/APIAuth";

export default {
  components: { Stepper, VuePhoneNumberInput, mobileVerifyModal },
  mixins: [mixinProfile, mixinAPIAuth],
  data() {
    return {
      mobileForPropval: "",
      changeBorder: {
        border: "1px solid #ccc",
      },
      changeBorderFixedLine: {
        border: "1px solid #ccc",
      },
      isNicValid: false,
      lableNic: "ID Number ( xxxxxxxxxV/X or xxxxxxxxxxxx )",
      countdownseconds: 0,
      time: "",
      changeColor1: {
        border: "1px solid #ccc",
      },
      mobileValid: false,
      userType: "merchant",
      passwordLengthError: false,
      passworMatchError: false,
      disableOtpBtn: false,
      responseId: "",
      agree: false,
      news_and_feeds: true,
      password: "",
      confirmPassword: "",
      otpId: "",
      sentotp: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      blockresendopt: true,
      stepperOptions: {
        headers: [
          { title: "Title One" },
          { title: "Title Two" },
          { title: "Title Three" },
          { title: "Title Four" },
        ],
        prevText: "Previous",
        nextText: "Next",
      },
      mobile: "",
      form: {
        mobile: "",
        dial_code: "",
        county_code: "",
      },
      fixedLineValid: true,
      emailValid: true,
      passwordValid: false,
      confPasswordValid: false,
      passwordMissMatched: false,
      valRuleArray: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
      ],
      valRuleArrayConf: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "Match password",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
      ],
    };
  },
  watch: {
    countdownseconds: function (val) {
      var minutes = Math.floor(val / 60);
      var seconds = Math.floor(val % 60);
      this.time = minutes + " min " + seconds + " secs ";
      if (val === 0) {
        this.disableOtpBtn = true;
        this.blockresendopt = false;
      } else {
        this.disableOtpBtn = false;
        this.blockresendopt = true;
      }
      if (val == -1) {
        this.time = 0 + " min " + 0 + " secs ";
      }
    },
    // password: function(val){
    //     if(val.length<6){
    //         this.passwordLengthError = true;
    //     } else {
    //         this.passwordLengthError = false;
    //     }
    // },
    // confirmPassword: function(val){
    //     if(val != this.password){
    //         this.passworMatchError = true;
    //     } else {
    //         this.passworMatchError = false;
    //     }
    // }
    // password: function (val) {
    //   if (val == "") {
    //     this.confirmPassword = "";
    //   }
    //   this.valPassword();
    //   this.valConfPassword();
    // },
    // confirmPassword: function (val) {
    //   if (val != this.password) {
    //     this.passwordMissMatched = true;
    //   } else {
    //     this.passwordMissMatched = false;
    //   }
    // },
  },
  created() {
    this.init();
    // this.countdownCal(90);
    //   this.validateEmail();
    this.countdownCal(90);
  },
  mounted() {
    // this.changeSteps();

    this.$nextTick(function () {
      const mobileInputMer = document.querySelector("#mobileInputMer");
      mobileInputMer.style.boxShadow = "none";
    });
  },
  methods: {
    validateNumber(str, condition) {
      // str = input value,    condition = nic or passport type
      if (condition === 1 || condition === "1") {
        if (str !== "") {
          if (/^[0-9]{9}[vVxX]$/.test(str)) {
            this.isNicValid = true;
            this.changeBorder.border = "1px solid green";
          } else {
            if (/^[0-9]{12}$/.test(str)) {
              this.isNicValid = true;
              this.changeBorder.border = "1px solid green";
            } else {
              this.isNicValid = false;
              this.changeBorder.border = "1px solid red";
            }
          }
        } else {
          this.isNicValid = false;
          this.changeBorder.border = "1px solid red";
        }
      } else if (condition === 2 || condition === "2") {
        if (str !== "") {
          this.isNicValid = true;
          if (/^([A-Z a-z]){1}([0-9]){7}$/.test(str) && str.includes("N")) {
            this.changeBorder.border = "1px solid green";
          } else {
            this.changeBorder.border = "1px solid red";
          }
        } else {
          this.isNicValid = false;
          this.changeBorder.border = "1px solid #red";
        }
      }
    },
    // changeType: function (id) {
    //   this.isNicValid = false;
    //   this.form.if_proof_type = id;
    //   this.form.id_proof = "";
    //   this.changeBorder.border = "1px solid red";
    //   if (id === "1") {
    //     this.lableNic = "ID Number ( xxxxxxxxxV/X or xxxxxxxxxxxx )";
    //   } else if (id === "2") {
    //     this.lableNic = "Passport Number ( NXXXXXXX )";
    //   }
    // },
    movetoNext: function (current, nextFieldID) {
      let currInput = document.getElementById(current);
      if (currInput.value.length >= currInput.maxLength) {
        document.getElementById(nextFieldID).focus();
      }
    },
    validateEmail(str) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(str).toLowerCase()) === true) {
        this.changeColor1.border = "1px solid green";
        this.emailValid = true;
      } else {
        this.changeColor1.border = "1px solid red";
        this.emailValid = false;
      }
      if (str === "") {
        this.changeColor1.border = "1px solid #ccc";
        this.emailValid = false;
      }
    },
    fixedLineNumber(number) {
      if (number === "") {
        this.changeBorderFixedLine.border = "1px solid #ccc"
        this.fixedLineValid = true;
      } else {
        number = number.replace(/[^0-9]/g, "");;
        this.form.fixed_line_number = number;
        if (/^[0-9]{10}$/.test(number)) {
          this.changeBorderFixedLine.border = "1px solid green";
          this.fixedLineValid = true;
        } else {
          this.changeBorderFixedLine.border = "1px solid red";
          this.fixedLineValid = false;
        }
      }
    },
    validatePhoneNO(key) {
      if (key === "0") {
        this.form.mobile = "";
        this.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      if (key.charAt(0) === "0" || this.mobile.charAt(0) === "0") {
        this.mobileValid = false;
        key = key.substr(1);
        this.mobile = this.mobile.substr(1);
        if (
          key.charAt(3) === " " ||
          this.mobile.charAt(3) === " " ||
          key.charAt(7) === " " ||
          this.mobile.charAt(7) === " "
        ) {
          this.mobile = this.mobile.replace(/ /g, "");
          key = this.mobile;
        }
        if (key.length == 9) {
          this.mobileValid = true;
        } else if (key.length <= 10) {
          this.mobileValid = false;
        }
      }
      this.form.mobile = key;
      if (key.length == 9) {
        this.mobileValid = true;
        this.$refs.mobileReg.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
      } else if (key.length < 9) {
        this.mobileValid = false;
        this.$refs.mobileReg.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }
      if (key.length > 9){
        this.mobileValid = false;
        this.$refs.mobileReg.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobileReg.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }
    },
    handleOpenNewTab() {
      var myWindow1 = window.open("", "MsgWindow");
      myWindow1.document.write("<p>Cochchi.lk</p>");
    },
    showChangeMobileModal: function () {
      $("mobileVerifyModalContentCom").modal("show");
      this.userType = "merchant";
    },
    toggleNewPwd: function () {
      if (this.$refs.inputRegPwdMer.type === "password") {
        this.$refs.inputRegPwdMer.type = "text";
        this.$refs.toggleNewIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputRegPwdMer.type = "password";
        this.$refs.toggleNewIcon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
    toggleNewPwdCon: function () {
      if (this.$refs.inputRegPwdMerCon.type === "password") {
        this.$refs.inputRegPwdMerCon.type = "text";
        this.$refs.toggleNewIconCon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputRegPwdMerCon.type = "password";
        this.$refs.toggleNewIconCon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
    changeMobileNo: async function (mobile) {
      try {
        this.form.mobile = mobile;
        let obj = {
          mobile: mobile,
        };
        let res = await this.merchantChangeMobileProfile(obj);
        this.$refs.modal.closeModal();
        this.$refs.modal.mobile = "";
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully changed mobile no",
          showConfirmButton: false,
          timer: 1500,
        });
        this.input1 = "";
        this.input2 = "";
        this.input3 = "";
        this.input4 = "";
        this.input5 = "";
        this.input6 = "";
        this.countdownCal(90);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    // changeSteps: function () {
    //   var stepNodes = document.getElementsByClassName("step-header-content");
    //   var idBtn = document.getElementsByClassName("next-button");
    //   idBtn[0].style.display = "none";
    //   var stepsCount = stepNodes.length;
    //   for (var i = 0; i < stepsCount; i++) {
    //     stepNodes[i].innerHTML = "";
    //   }
    // },
    goBack: function () {
      var preBtns = document.getElementsByClassName("prev-button");
      preBtns[0].click();
    },
    init: async function () {
       try {
         let response = await this.getProfileBacicInfo();
         this.form.mobile = response.mobile;
         this.form.county_code = response.dial_code;
         this.form.dial_code = response.county_code;
         this.responseId = response._id;
         this.mobileForPropval = this.form.mobile;
       } catch (error) {
        throw error;
       }
    },
    setPhoneNo(payload) {
      this.form.mobile = payload.formattedNumber;
      this.form.county_code = payload.countryCode;
      this.form.dial_code = "+" + payload.countryCallingCode;
      this.mobile = payload.formattedNumber;
    },
    goToNext: function () {
      var nextBtns = document.getElementsByClassName("next-button");
      nextBtns[0].click();
    },
    veryfyOtp: async function () {
      try {
        this.sentotp =
          this.input1 +
          this.input2 +
          this.input3 +
          this.input4 +
          this.input5 +
          this.input6;
        let obj = {
          code: this.sentotp,
        };
        let res = await this.merchantMobileVerifyProfile(obj);
        
        if (res) {
          this.otpId = res.data._id;
        //   this.goToNext();
        }
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully verified OTP!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("merchant-profile");
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Invalid OTP" + "",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    handleLogin() {
      this.$router.push("merchant-landing-new");
    },
    countdownCal(duration) {
      this.countdownseconds = duration;
      duration = 90;
      let self = this;
      var downloadTimer = setInterval(function () {
        self.countdownseconds -= 1;
        if (self.countdownseconds <= 0) {
          clearInterval(downloadTimer);
        }
      }, 1500);
    },
    // registerMerchant: async function () {
    //   try {
    //     if (this.news_and_feeds === true) {
    //       this.form.news_feed_status = 2;
    //       this.form.news_feed_method = "111";
    //     } else {
    //       this.form.news_feed_status = 3;
    //       this.form.news_feed_method = "111";
    //     }

    //     this.form.mobile = this.form.dial_code + this.mobile;
    //     let response = await this.merchantRegister(this.form);
    //     if (response.data._id !== "") {
    //       this.$swal.fire({
    //         position: "center",
    //         type: "success",
    //         title: "Successfully Registered!",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.responseId = response.data._id;
    //       this.goToNext();
    //       this.countdownCal(90);
    //       localStorage.removeItem("merchat_form");
    //     }
    //   } catch (error) {
    //     this.$swal.fire({
    //       position: "center",
    //       type: "error",
    //       title: error.data.message,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   }
    // },
    resentOTP: async function () {
      this.countdownCal(90);
      try {
        let res = await this.merchantMobileResend(this.responseId);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully resent OTP",
          showConfirmButton: false,
          timer: 2000,
        });
        this.countdownseconds = 90;
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Failed to resent OTP",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    // validatePassword(){
    //     if(this.password === this.confirmPassword){
    //         return true;
    //     }
    //     else {
    //         this.$swal.fire({
    //             position: 'center',
    //             type: 'error',
    //             title: "Please enter same password",
    //             showConfirmButton: false,
    //             timer: 2000
    //         });
    //     }
    // },
    // valPassword: function () {
    //   var pattOne = /(?=.*[a-z])/;
    //   var pattTwo = /(?=.*[A-Z])/;
    //   var pattThree = /(?=.*\W)/;
    //   // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

    //   if (pattOne.test(this.password) == true) {
    //     this.valRuleArray[1].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArray[1].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (pattTwo.test(this.password) == true) {
    //     this.valRuleArray[2].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArray[2].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (pattThree.test(this.password) == true) {
    //     this.valRuleArray[3].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArray[3].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (this.password.length >= 8 == true) {
    //     this.valRuleArray[0].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArray[0].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (
    //     pattOne.test(this.password) == true &&
    //     pattTwo.test(this.password) == true &&
    //     pattThree.test(this.password) == true &&
    //     this.password.length >= 8
    //   ) {
    //     this.passwordValid = true;
    //   } else {
    //     this.passwordValid = false;
    //   }
    // },
    // valConfPassword: function () {
    //   var pattOne = /(?=.*[a-z])/;
    //   var pattTwo = /(?=.*[A-Z])/;
    //   var pattThree = /(?=.*\W)/;
    //   // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

    //   if (pattOne.test(this.confirmPassword) == true) {
    //     this.valRuleArrayConf[1].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArrayConf[1].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (pattTwo.test(this.confirmPassword) == true) {
    //     this.valRuleArrayConf[2].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArrayConf[2].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (pattThree.test(this.confirmPassword) == true) {
    //     this.valRuleArrayConf[3].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArrayConf[3].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (this.confirmPassword.length >= 8 == true) {
    //     this.valRuleArrayConf[0].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArrayConf[0].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (this.confirmPassword === this.password) {
    //     this.valRuleArrayConf[4].valIcon =
    //       '<i style="color: green" class="fa fa-check-circle"></i>';
    //   } else {
    //     this.valRuleArrayConf[4].valIcon =
    //       '<i style="color: red" class="fa fa-times-circle"></i>';
    //   }
    //   if (
    //     pattOne.test(this.confirmPassword) == true &&
    //     pattTwo.test(this.confirmPassword) == true &&
    //     pattThree.test(this.confirmPassword) == true &&
    //     this.confirmPassword.length >= 8 &&
    //     this.confirmPassword === this.password
    //   ) {
    //     this.confPasswordValid = true;
    //   } else {
    //     this.confPasswordValid = false;
    //   }
    // },
    // setPassword: async function () {
    //   try {
    //     let obj = {
    //       _id: this.responseId,
    //       password: this.password,
    //     };
    //     let res = await this.setMerchantPasswordApi(obj);
    //     this.handleLogin();
    //     this.$swal.fire({
    //       position: "center",
    //       type: "success",
    //       title: "Successfully set password",
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //   } catch (error) {
    //     this.$swal.fire({
    //       position: "center",
    //       type: "error",
    //       title: "Failed to set password",
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //   }
    // },
    togglePwd: function () {
      if (this.$refs.inputRegPwdMer.type === "password") {
        this.$refs.inputRegPwdMer.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputRegPwdMer.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
  },
};
</script>

<style>
.error-message {
  margin-top: -12px;
  color: red;
}
.foot {
  display: none !important;
}
.btn-next1 {
  margin-top: 30vh;
}
.btn-next {
  width: 110px;
  height: 40px;
  border-radius: 5px;
}
.align-right-rec {
  /* display: flex; */
  /* align-items: center; */
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.form-control {
  height: 41px !important;
}

#fixedLineNumber {
  height: 41px !important;
}
/* #stepCont .top{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
    width: 100%;
    justify-content: center;
} */
#stepCont .step-header {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  display: none;
}
#stepCont .header-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#stepCont .step-header.start > .header-indicator {
  align-self: flex-end;
}
#stepCont .step-header-line {
  display: inline-flex;
  width: 100px;
  height: 0px !important;
  background-color: none !important;
  border: 1px dotted grey;
}
#stepCont .step-header-content {
  /* pointer-events: none; */
  display: inline-flex;
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  background-color: grey;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#stepCont .step-header.active .step-header-content {
  background-color: #ffd338 !important;
}
#stepCont .step-header.active .step-header-line {
  border: 1px solid #ffd338 !important;
}
#stepCont .title {
  display: none !important;
  align-self: flex-end;
  text-transform: capitalize;
  width: 100px;
  position: relative;
  right: -10%;
  justify-content: center;
}
#stepCont .step-header.start > .title {
  right: -35%;
}

#stepCont .steps-item {
  min-width: 100%;
}
#stepCont .body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
#stepCont button {
  width: 110px !important;
  padding: 7px 15px;
  color: white;
  background-color: #ffd338 !important;
  border: 0;
  outline: 0;
  border-radius: 5px;
  margin-top: 30px !important;
}
#stepCont .stepper {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#stepCont .step-content-box {
  text-align: center;
  color: #2e2e2e;
}
#stepCont .step-content-box h3 {
  font-size: 20px;
  margin-top: 60px;
}
#stepCont .step-content-box .step-content-text {
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 60px;
}
#stepCont .input-container {
  width: 70%;
  margin: 60px auto 100px auto;
  text-align: left;
}
#stepCont .input-container .otp-digit {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
#stepCont .input-container .otp-digit .input-lg {
  padding-left: 2px !important;
  padding-right: 2px !important;
  width: 70px !important;
  height: 70px !important;
}
#stepCont .input-container span {
  /* color: red; */
}
#stepCont .step-content-box a {
  color: #ffd338;
}
#stepCont .stepper-slide-1-enter-active {
  animation: slide-in 0.3s;
}
#stepCont .stepper-slide-1-leave-active {
  animation: slide-out 0.3s;
}
#step-4 .input-password input[type="password"] {
  position: relative;
}
#step-4 .input-password span.toggleIcon {
  position: relative;
  display: block;
  /* float: right; */
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#step-4 .input-password span.toggleIcon .fa {
  color: #000000;
  font-size: 15px;
}
.merchregstep-btn {
  background: #ffd338;
  color: #000000;
  border: none;
}
.merchregstep-btn:hover {
  color: #ffffff;
}
.news-feed a:hover {
  color: #ffd338;
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

#stepCont .stepper-slide-2-enter-active {
  animation: slide2-in 0.3s;
}
#stepCont .stepper-slide-2-leave-active {
  animation: slide2-out 0.3s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(255, 211, 56, 0.8);
  box-shadow: 0 1px 1px rgba(255, 211, 56, 0.075) inset,
    0 0 8px rgba(255, 211, 56, 0.6);
  outline: 0 none;
}
@keyframes slide2-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide2-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 992px) {
  #stepCont .input-container {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .align-right-rec {
    right: 20px;
    width: 60%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #stepCont .input-container {
    width: 90%;
  }
}
@media screen and (max-width: 575px) {
  #stepCont .step-header-line {
    width: 50px !important;
  }
  .align-right-rec {
    right: 40px;
  }
  #stepCont .input-container .otp-digit .input-lg {
    width: 50px !important;
    height: 50px !important;
  }
}
@media screen and (max-width: 425px) {
  .align-right-rec {
    right: 25px;
    width: 95%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #stepCont .input-container .otp-digit .input-lg {
    width: 35px !important;
    height: 35px !important;
  }
}
</style>