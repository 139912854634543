<template>
  <div id="guideContainer" class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="contentDiv">
                <h2>Here's how you can add your products to <a href="https://cochchi.lk/">Cochchi.lk</a></h2><br/>
                <p>Here's how you can add your products to <a href="https://cochchi.lk/">Cochchi.lk</a></p>
                <p>Do you wish to upload your products in Bulk? Then click on the 'Add New Bulk' button and download the Instructions on the page.</p>
                <p id="stepTitle">If you wish to add products one by one, follow the steps below:</p>
                <p><b>Step 1:</b> If you want to upload images, Visit the Gallery section and upload your product images there. Proceed to Step 2, if you wish to copy paste a URL.</p>
                <p><b>Step 2:</b> Click on Add New button and select the store from top to which you wish to add the products.</p>
                <p><b>Step 3:</b> Fill in the Mandatory details and don't forget to select the delivery partner.</p>
                <p><b>Step 4:</b> Click Submit when you are done and the product will be added to your store.</p>
                <button class="pull-right continue-btn btn" @click="continueClick()">Continue</button>
            </div>
        </div>
    </div>      
  </div>
</template>
<script>

export default {
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    continueClick: function(){
        this.$router.push({name:"Merchant Product"});
    }
  },
};
</script>
<style scoped>
#guideContainer {
    background-color: white;
}

#contentDiv {
    padding: 20px;
}

#contentDiv p{
    font-size: 15px;
    color: black;
    text-align: justify;
}

#contentDiv h2{
    color: black;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: solid 3px lightgrey;
    font-size: 22px;
}

#contentDiv a {
    color: #005baa !important;
}

#contentDiv .continue-btn {
    margin-bottom: 20px;
    background: #5779ae;
    color: #ffffff;
    border: none;
    width: 100px;
    height: 30px;
    border-radius: 6px;
}

#contentDiv .continue-btn:hover {
    color: black;
}

#stepTitle {
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>