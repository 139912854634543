var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"guideContainer"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{attrs:{"id":"contentDiv"}},[_vm._m(0),_c('br'),_vm._m(1),_c('p',[_vm._v("Do you wish to upload your products in Bulk? Then click on the 'Add New Bulk' button and download the Instructions on the page.")]),_c('p',{attrs:{"id":"stepTitle"}},[_vm._v("If you wish to add products one by one, follow the steps below:")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('button',{staticClass:"pull-right continue-btn btn",on:{"click":function($event){return _vm.continueClick()}}},[_vm._v("Continue")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Here's how you can add your products to "),_c('a',{attrs:{"href":"https://cochchi.lk/"}},[_vm._v("Cochchi.lk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Here's how you can add your products to "),_c('a',{attrs:{"href":"https://cochchi.lk/"}},[_vm._v("Cochchi.lk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Step 1:")]),_vm._v(" If you want to upload images, Visit the Gallery section and upload your product images there. Proceed to Step 2, if you wish to copy paste a URL.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Step 2:")]),_vm._v(" Click on Add New button and select the store from top to which you wish to add the products.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Step 3:")]),_vm._v(" Fill in the Mandatory details and don't forget to select the delivery partner.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Step 4:")]),_vm._v(" Click Submit when you are done and the product will be added to your store.")])
}]

export { render, staticRenderFns }